<template>
  <div class="sender">
    <add-sender
      :add-sender-modal="addSenderModal"
      :domain="domain"
      :selected-client="selectedClient"
      @closeAddSenderModal="closeAddSenderModal"
      @failedAddingSender="failedAddingSender"
      @senderSaved="senderSaved"
    ></add-sender>
    <edit-signature
      :edit-signature-modal="editSignatureModal"
      :account="currentAccount"
      @closeEditSignatureModal="closeEditSignatureModal"
      @failedEditingSignature="failedEditingSignature"
      @signatureSaved="signatureSaved"
    ></edit-signature>
    <v-row>
      <v-col cols="6">
        <v-card flat>
          <v-card-title class="text-h6"> Domain Details </v-card-title>
          <v-card-text>
            <v-container>
              <v-list two-line>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-web </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title
                      >{{ domain.name }}
                      <v-badge
                        offset-x="0"
                        offset-y="-5"
                        overlap
                        dot
                        color="green"
                        v-if="domain.domainStatus === 'VERIFIED'"
                      ></v-badge>
                      <v-badge
                        offset-x="0"
                        offset-y="-5"
                        overlap
                        dot
                        color="yellow"
                        v-else
                      ></v-badge>
                    </v-list-item-title>
                    <v-list-item-subtitle>Domain Name</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-btn
                    v-if="domain && domain.domainStatus === 'UNVERIFIED'"
                    class="mx-2"
                    fab
                    dark
                    small
                    color="green"
                    @click="verifyDomain()"
                  >
                    <v-icon>mdi-check-decagram-outline</v-icon>
                  </v-btn>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-email-receive-outline </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title
                      >{{ `${domain.imapHost} (${domain.imapPort})` }}
                    </v-list-item-title>
                    <v-list-item-subtitle>IMAP Settings</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-email-send-outline </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title
                      >{{ `${domain.smtpHost} (${domain.smtpPort})` }}
                    </v-list-item-title>
                    <v-list-item-subtitle>SMTP Settings</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card flat>
          <v-card-title class="text-h6"> Sender Accounts </v-card-title>
          <v-card-text>
            <v-container>
              <v-row justify="center">
                <v-expansion-panels flat tile>
                  <v-expansion-panel
                    v-for="account in domain.accounts"
                    :key="account._id"
                  >
                    <v-expansion-panel-header>{{
                      account.fullName || account.user
                    }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-list two-line>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon> mdi-account-outline </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title
                              >{{ account.fullName }}
                            </v-list-item-title>
                            <v-list-item-subtitle
                              >Sender Name</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon> mdi-email-outline </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title
                              >{{ account.user }}
                            </v-list-item-title>
                            <v-list-item-subtitle
                              >Sender Email</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon> mdi-lock-outline </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <samp
                                    v-bind="attrs"
                                    v-on="on"
                                    class="copy-password"
                                    @click="copyPassword(account._id)"
                                    >***********</samp
                                  >
                                </template>
                                <span>Click to copy</span>
                              </v-tooltip>
                            </v-list-item-title>
                            <v-list-item-subtitle
                              >Sender Password</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-spacer></v-spacer>
                          <v-btn
                            class="mx-2"
                            fab
                            dark
                            small
                            color="red darken-2"
                            @click="deleteAccount(account)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                          <v-btn
                            class="mx-2"
                            fab
                            dark
                            small
                            color="purple"
                            @click="signature(account)"
                          >
                            <v-icon>mdi-draw</v-icon>
                          </v-btn>
                        </v-list-item>
                      </v-list>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="indigo"
                  @click="addSenderModal = true"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AddSender from "@/components/AddSender.vue";
import { store } from "@/state/store.js";
import EditSignature from "@/components/EditSignature.vue";

export default {
  components: {
    AddSender,
    EditSignature,
  },
  data() {
    return {
      domain: {},
      currentAccount: {},
      addSenderModal: false,
      editSignatureModal: false,
      store,
    };
  },
  created() {
    this.loadDomain();
  },
  computed: {
    selectedClient() {
      return this.store.selectedClient;
    },
  },
  methods: {
    async copyPassword(accountId) {
      const passwordResponse = await this.$http.get(
        `/domains/accounts/${accountId}/password`
      );
      navigator.clipboard.writeText(passwordResponse.data.decryptedPassword);
    },
    async loadDomain() {
      try {
        const domainResponse = await this.$http.get(
          `/domains/${this.$route.params.domainId}`
        );
        this.domain = domainResponse.data;
      } catch (error) {
        console.log(error);
      }
    },
    async verifyDomain() {
      try {
        await this.$http.post("/domains/verify", {
          domainName: this.domain.name,
        });
        this.loadDomain();
      } catch (error) {
        console.log(error);
      }
    },
    async deleteAccount(selectedAccount) {
      try {
        await this.$http.delete(`/domains/accounts/${selectedAccount._id}`);
        this.loadDomain();
      } catch (error) {
        console.log(error);
      }
    },
    signature(selectedAccount) {
      this.currentAccount = selectedAccount;
      this.editSignatureModal = true;
    },
    closeAddSenderModal() {
      this.addSenderModal = false;
    },
    failedAddingSender(error) {
      console.log(error);
      this.addSenderModal = false;
    },
    senderSaved() {
      this.loadDomain();
      this.addSenderModal = false;
    },
    closeEditSignatureModal() {
      this.editSignatureModal = false;
    },
    failedEditingSignature(error) {
      console.log(error);
      this.editSignatureModal = false;
    },
    signatureSaved() {
      this.editSignatureModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.copy-password {
  cursor: pointer;
  font-weight: 700;
}
</style>
