<template>
  <v-dialog
    v-model="editSignatureModal"
    max-width="850px"
    @click:outside="closeModal()"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Edit Signature</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" class="mx-2" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="account.signature"
                  outlined
                  label="Signature"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <div
                  v-if="account.signature"
                  v-html="account.signature"
                  class="signature"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeModal()"> Close </v-btn>
        <v-btn color="primary" text @click="updateSignature()"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    editSignatureModal: Boolean,
    account: Object,
  },
  methods: {
    closeModal() {
      this.$emit("closeEditSignatureModal");
    },
    async updateSignature() {
      if (!this.$refs.form.validate()) {
        return;
      }

      try {
        const response = await this.$http.put(
          `/domains/accounts/${this.account._id}/signature`,
          {
            signature: this.account.signature,
          }
        );
        this.$emit("signatureSaved", response.data);
      } catch (err) {
        this.$emit("failedEditingSignature", err);
      }
    },
  },
};
</script>

<style lang="scss">
.signature {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 25px;
}
</style>
