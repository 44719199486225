<template>
  <v-dialog
    v-model="addSenderModal"
    max-width="650px"
    @click:outside="closeModal()"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Add Sender Accounts</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" class="mx-2" lazy-validation>
            <v-row>
              <v-col cols="12" v-if="accounts.length">
                <div v-for="account in accounts" :key="account.user">
                  {{
                    `${account.fullName} &lt;${account.user}@${domain.name}&gt;`
                  }}
                </div>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="accountFullName"
                  label="Full Name*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="accountUser"
                  label="Username*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="accountPassword"
                  label="Password*"
                  type="password"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-btn
                  small
                  text
                  color="accent"
                  style="float: right"
                  @click="addAccount"
                  >Add account</v-btn
                >
              </v-col></v-row
            ></v-form
          >
        </v-container>
        <small>* indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeModal()"> Close </v-btn>
        <v-btn color="primary" text @click="createSenders()"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      accounts: [],
      accountUser: "",
      accountPassword: "",
      accountFullName: "",
    };
  },
  props: {
    addSenderModal: Boolean,
    domain: Object,
    selectedClient: undefined,
  },
  methods: {
    closeModal() {
      this.$emit("closeAddSenderModal");
    },
    async createSenders() {
      if (!this.$refs.form.validate()) {
        return;
      }

      try {
        const response = await this.$http.post(
          `/domains/${this.domain._id}/accounts`,
          {
            clientId: this.selectedClient,
            accounts: this.accounts,
          }
        );
        this.$emit("senderSaved", response.data);
      } catch (err) {
        this.$emit("failedAddingSender", err);
      }
    },
    addAccount() {
      this.accounts.push({
        user: this.accountUser,
        password: this.accountPassword,
        fullName: this.accountFullName,
      });

      this.accountUser = "";
      this.accountPassword = "";
      this.accountFullName = "";
    },
  },
};
</script>
